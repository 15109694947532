import React, { useRef, useState, useEffect, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Menu,
  IconButton,
  InputLabel,
  FormControl,
  Box,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";



import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import calender from "../../../../../assets/icons/calender.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import transactionFilter from "../../../../../assets/icons/transactionFilter.svg";
import uploadIcon from "../../../../../assets/icons/orange-upload-icon.svg";
import arrowCircleRightIcon from "../../../../../assets/icons/arrow_circle_right_icon.svg";
import calendarIconSvg from "../../../../../assets/icons/arrow_circle_right_icon.svg";
import checkCircle from "../../../../../assets/icons/check_circle.svg";
import helpIcon from "../../../../../assets/icons/help.svg";
import FontIcon from "react-md/lib/FontIcons";
import OptionsIcon from "react-md-icon/dist/RoundMoreVert";
import NavigoAPIServices from "../services/navigoAPIServices";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";
import Button from "react-md/lib/Buttons/Button";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import { Modal } from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import PatientsService from "../../../../ops/modules/patients/service/PatientsService";
import { CircularProgress } from "react-md";
import { green100 } from "material-ui/styles/colors";
import CommonService from "../../../../ops/modules/patients/modules/new-bridge/services/CommonService";
import Helper from "util/Helper";

const claimStatus = [
  { value: 1, name: "Received" },
  { value: 2, name: "Approved" },
  { value: 3, name: "Rejected" },
  { value: 4, name: "Processed" },
  { value: 5, name: "Appealed" },
  { value: 6, name: "On Hold" },
];

const allDocumentTypesList = [{ name: "Not specified", value: 0 }, { name: "Softcopy", value: 1 }, { name: "Hardcopy", value: 2 }];

const generalNavigoStatus = [
  // { value: 1, name: "In progress" },
];

function ClaimsDashboard(props) {
  const [uploading, setUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [claimModal, setClaimModal] = useState(false);
  const [icdModal, setIcdModal] = useState(false);
  const [data, setData] = useState([]);
  const [remarksModal, setRemarksModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [appointList, setAppointList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuFor, setMenuFor] = useState({});
  const [componentMounted, setComponentMounted] = useState(false);
  const [patientId, setPatientId] = useState();
  const [patientFullName, setPatientFullName] = useState("");
  const [claimId, setClaimId] = useState("");
  const [searchDisabled, setSearchDisabled] = useState(null);
  const [rejectModal, setRejectModal] = useState(false);
  const [approvedModal, setApproveModal] = useState(false);
  const [processedModal, setProcessedModal] = useState(false);
  const [onHoldModal, setOnHoldModal] = useState(false);
  const [id, setId] = useState();
  const [walletId, setWalletId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [editClaimTypeField, setEditClaimTypeField] = useState(null);
  const [editClaimTypeReason, setEditClaimTypeReason] = useState("");
  const [editClaimTypeLimitRes, setEditClaimTypeLimitRes] = useState(null);
  const [isValidClaimTypeChange, setIsValidClaimTypeChange] = useState(false);
  const [isValidClaimFile, setIsValidClaimFile] = useState(false);
  const [claimLimitExceptionCheckbox, setClaimLimitExceptionCheckbox] = useState(false);
  const [recheckEditClaimTypeCarefully, setRecheckEditClaimTypeCarefully] = useState(false);
  const [isFileClaimContinueClicked, setIsFileClaimContinueClicked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const allStatusList = useMemo(() => {
    let firstCount = false;

    return generalNavigoStatus.concat(claimStatus).filter((ele) => {
      if (ele.value === 4) {
        if (firstCount) {
          return false;
        }
        firstCount = true;
        return true;
      }
      return true;
    });
  }, []);


  const handleClick = (event, claim) => {
    console.log("hello", event, claim);
    setAnchorEl(event.currentTarget);
    setMenuFor(claim);
    const matchingAppointment = appointList.find(
      (appointment) => appointment.id === claim.id
    );
    setMatchingAppointment(matchingAppointment);
    const comment = matchingAppointment?.claimReasonDesc || "";
    const claimIcdReason = (matchingAppointment?.claimICDReason || []).map(
      (reason) => ({
        code: reason.code,
        label: reason.label,
        change: false,
        suggestions: [],
      })
    );
    setClaimIcdReason(claimIcdReason);
    setClaimReasons(comment);
  };
  const [dialog, setDialog] = useState(false);
  const [dialogType, setDialogType] = useState(1);
  const [report, setFileReport] = useState(false);
  const [noAppointment, setNoAppointment] = useState(true);
  const [loading, setLoading] = useState(true);
  const [maxid, setMaxid] = useState(0);
  const [clientCodeList, setClientCodeList] = useState([]);
  const [reasonList, setReasonList] = useState([]);
  const [reason, setReason] = useState("");
  const [approveRemarks, setApproveRemarks] = useState("");
  const [processRemarks, setProcessRemarks] = useState("");
  const [onHoldRemarks, setOnHoldRemarks] = useState("");
  const [onHoldUploadDocumentsAgainCheckbox, setOnHoldUploadDocumentsAgainCheckbox] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [approveClaim, setApproveClaim] = useState();
  const [processClaim, setProcessClaim] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [numFrom, setNumFrom] = useState(0);
  const [numTo, setNumTo] = useState(0);
  const [searchlist, setSearchlist] = useState("");
  const [clientCodeFilter, setClientCodeFilter] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [claimDateRangeFilter, setClaimDateRangeFilter] = useState(false);
  const [claimUpdatedDateRangeFilter, setClaimUpdatedDateRangeFilter] = useState(false);
  const [invoiceDateRangeFilter, setInvoiceDateRangeFilter] = useState(false);

  const [clientCodeFilterValue, setClientCodeFilterValue] = useState([]);
  const [claimDateRangeFilterValueFrom, setClaimDateRangeFilterFrom] =
    useState(null);
  const [claimDateRangeFilterValueTo, setClaimDateRangeFilterTo] =
    useState(null);
  const [claimUpdatedDateRangeFilterValueFrom, setClaimUpdatedDateRangeFilterFrom] =
  useState(null);
  const [claimUpdatedDateRangeFilterValueTo, setClaimUpdatedDateRangeFilterTo] =
    useState(null); 
  const [invoiceDateRangeFilterValueFrom, setInvoiceDateRangeFilterFrom] =
    useState(null);
  const [invoiceDateRangeFilterValueTo, setInvoiceDateRangeFilterTo] =
    useState(null);
  const [triggerClaimsFetch, setTriggerClaimsFetch] = useState(false);
  const [statusFilter, setStatusFilter] = useState(false);
  const [documentTypeFilter, setDocumentTypeFilter] = useState(false);
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [documentTypeFilterValue, setDocumentTypeFilterValue] = useState([]);
  const [clickedClaimTicketEvents, setClickedClaimTicketEvents] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [documentDataWithStatus, setDocumentDataWithStatus] = useState([]);
  const [openFilter, setOpenFilter] = useState(0);
  const skip = useRef(30);
  const appointComplete = useRef(null);
  const appointValue = useRef(null);
  const dateOfInvoiceInputRef = useRef(null);

  const openDatePicker = () => {
    if (dateOfInvoiceInputRef?.current && dateOfInvoiceInputRef.current.showPicker) {
      dateOfInvoiceInputRef.current.showPicker(); // For modern browsers
    }
    else if (dateOfInvoiceInputRef?.current) {
      dateOfInvoiceInputRef.current.focus(); // Fallback for older browsers
    }
  };
  const fileURL = useRef(null);
  const [claimReasons, setClaimReasons] = useState([]);
  const [matchingAppointment, setMatchingAppointment] = useState(null);
  const [claimIcdReason, setClaimIcdReason] = useState([]);
  const [token, setToken] = useState("");
  const [claimFileFormFields, setClaimFileFormFields] = useState({});
  const [editClaimFormFields, setEditClaimFormFields] = useState({});
  const [openClaimFileDialog, setOpenClaimFileDialog] = useState(false);
  const [openEditClaimTypeDialog, setOpenEditClaimTypeDialog] = useState(false);
  const [editClaimTypeClaimTypesArr, setEditClaimTypeClaimTypesArr] = useState([]);
  const [editClaimTypeClaimConfig, setEditClaimTypeClaimConfig] = useState({});
  const [relations, setRelations] = useState([]);
  const [fileClaimFamilyMembersArr, setFileClaimFamilyMembersArr] = useState([]);

  const [claimTypeInstructions, setclaimTypeInstructions] = useState([]);
  const [selectedClaimType, setselectedClaimType] = useState(null);

  const uploadFileRef = useRef(null);
  const uploadDocumentRef = useRef(null);
  const dateOfInvoiceFieldRef = useRef(null);
  const navigoAPIServices = new NavigoAPIServices();
  const authenticationService = new AuthenticationService();
  const patientService = new PatientsService();
  const commonServices = new CommonService();
  const helper = new Helper();

  useEffect(() => {
    getAllClaims(1);
    handleGetAllClientCode();
    getAllReasons();
    setComponentMounted(true);
    generateDocumentToken();
    getAllRelations();
  }, []);


  useEffect(() => {
    if (componentMounted) {
      getAllClaims(1);
    }
  }, [documentTypeFilterValue, clientCodeFilterValue, statusFilterValue]);

  useEffect(() => {
    if (componentMounted) {
      skip.current = 30;
      getAllClaims(1);
    }
  }, [documentTypeFilterValue, clientCodeFilterValue, statusFilterValue]);
  useEffect(() => {
    if (componentMounted) {
      if (searchlist === "") {
        getAllClaims(1);
      }
      if (patientId === "") {
        getAllClaims(1);
      }
      if (claimId === "") {
        getAllClaims(1);
      }
      if (patientFullName === "") {
        getAllClaims(1);
      }
    }
  }, [searchlist]);

  useEffect(() => {
    if (
      claimDateRangeFilterValueFrom &&
      claimDateRangeFilterValueTo &&
      componentMounted
    ) {
      skip.current = 30;
      getAllClaims();
      setClaimDateRangeFilter(true);
    }
  }, [claimDateRangeFilterValueFrom, claimDateRangeFilterValueTo]);

  useEffect(() => {
    if (
      claimUpdatedDateRangeFilterValueFrom &&
      claimUpdatedDateRangeFilterValueTo &&
      componentMounted
    ) {
      skip.current = 30;
      getAllClaims();
      setClaimUpdatedDateRangeFilter(true);
    }
  }, [claimUpdatedDateRangeFilterValueFrom, claimUpdatedDateRangeFilterValueTo]);


  useEffect(() => {
    if (
      invoiceDateRangeFilterValueFrom &&
      invoiceDateRangeFilterValueTo &&
      componentMounted
    ) {
      skip.current = 30;
      getAllClaims();
      setInvoiceDateRangeFilter(true);
    }
  }, [invoiceDateRangeFilterValueFrom, invoiceDateRangeFilterValueTo]);

  useEffect(() => {
    if (componentMounted) {
      handleIsValidClaimTypeChange();
    }
  }, [editClaimTypeLimitRes, claimLimitExceptionCheckbox, editClaimTypeReason]);

  useEffect(() => {
    if (componentMounted) {
      handleIsValidClaimFile();
    }
  }, [claimFileFormFields, documentDataWithStatus]);

  useEffect(() => {
    if (componentMounted && documentDataWithStatus && documentDataWithStatus.length > 0) {
      createReimbursement();
    }
  }, [documentDataWithStatus]);

  useEffect(() => {
    if (componentMounted) {
      if (editClaimTypeClaimConfig?.invoiceMinDays && editClaimTypeClaimConfig?.invoiceMinDays != 0) {
        updateClaimFileFormFields({ minInvoiceDate: getMinInvoiceDate(editClaimTypeClaimConfig?.invoiceMinDays), maxInvoiceDate: getMaxInvoiceDate() })
      }
      else {
        updateClaimFileFormFields({ minInvoiceDate: getMinInvoiceDate(3650), maxInvoiceDate: getMaxInvoiceDate() })
      }
    }
  }, [editClaimTypeClaimConfig]);





  const handleGetAllClientCode = () => {
    navigoAPIServices.getAllClientCode(
      (res) => {
        if (res) {
          setClientCodeList([...res]);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot retrieve client code",
        });
      }
    );
  };

  const parseDateFromISO = (isoString) => {

    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    return formattedDate;
    // Formatted date: "27 Dec 2024"
  }

  const checkIsValidClaimTypeChange = () => {
    return Boolean(editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.typeId != editClaimFormFields?.reimbursementTypeId) && (Boolean(editClaimTypeLimitRes?.unlimited == true) || (editClaimFormFields?.claimAmount > editClaimTypeLimitRes?.available)));
  }

  const updateClaimFileFormFields = (updates) => {
    setClaimFileFormFields((prevFormFields) => ({
      ...prevFormFields,
      ...updates, // Merge the existing state with the new updates
    }));
  };

  const updateEditClaimFormFields = (updates) => {
    setEditClaimFormFields((prevFormFields) => ({
      ...prevFormFields,
      ...updates, // Merge the existing state with the new updates
    }));
  };

  const getAllReasons = () => {
    navigoAPIServices.rejectReason(
      (res) => {
        if (res) {
          setReasonList([...res]);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot retrieve client code",
        });
      }
    );
  };

  const handleDocFileInput = (event) => {

    const files = Array.from(event.target.files);
    if (documentDataWithStatus.length + files.length > 5) {
      alert('You can upload a maximum of 5 files.');
      return;
    }

    const newFiles = files.reduce((acc, file) => {

      if (file.size > 20000000) {
        alert(`File "${file.name}" is too large. Max allowed size is 20 MB.`);
      } else {
        acc.push({
          name: file.name,
          status: 'selected',
          url: URL.createObjectURL(file),
          fileObject: file,
        });
      }

      return acc;
    }, []);

    setDocumentDataWithStatus((prevDocs) => [...prevDocs, ...newFiles]);
    event.target.value = null;

  };

  const removeDocument = (event, index) => {
    event.stopPropagation();
    const docToRemove = documentDataWithStatus[index];
    // URL.revokeObjectURL(docToRemove.url);
    setDocumentDataWithStatus((prevDocs) => prevDocs.filter((_, i) => i !== index));
  };

  const updateDocumentDataWithStatusFields = (index, updates) => {
    setDocumentDataWithStatus((prevDocs) =>
      prevDocs.map((doc, i) =>
        i === index ? { ...doc, ...updates } : doc
      )
    );
  };


  const generateDocumentToken = () => {
    patientService.getAndSaveDocumentToken(
      (response) => {
        localStorage.setItem("zy.bridge.doc.token", JSON.stringify(response));
        setToken(response.token);
      },
      (error) => { }
    );
  };

  const fetchPatientDetailsById = (pid) => {
    if (pid && pid.length > 0 && typeof parseInt(pid) === "number") {
      navigoAPIServices.getPatientDataThroughID(
        pid,
        (res) => {
          if (res && res.id !== undefined) {
            let pName = res.firstName ?? "";
            pName = pName + " ";
            const lName = res.lastName ?? "";
            pName = pName + lName;
            updateClaimFileFormFields({ patientName: pName, clientCode: res.clientCode });
          } else {
            // setNameList([]);
            console.log("res empty or err");
          }
        },
        (err) => {
          console.log("res empty or err");
        }
      );
    }
  }

  const getAllClaims = (type, btnType = 0, pageNum = 1) => {
    setLoading(true);
    let filter = [];
    let isFilter = false;
    const intPatientId = parseInt(patientId);
    const filterClaimId = (claimId || "").toUpperCase();
    const filterPatientFullName = (patientFullName || "").toUpperCase();

    // filter = [ ...filter, {"field": "patientFullName","value":"RKEE clientjkxnsajnjkasjk","op":"eq"} ];
    // isFilter = true;


    if (!isNaN(intPatientId)) {
      filter = [...filter, { "field": "patientId", "value": intPatientId, "op": "eq" }];
      isFilter = true;
    }
    if (filterClaimId) {
      filter = [...filter, { "field": "claimId", "value": filterClaimId, "op": "eq" }];
      isFilter = true;
    }
    if (filterPatientFullName && filterPatientFullName != "") {
      filter = [...filter, { "field": "patientFullName", "value": filterPatientFullName, "op": "like" }];
      isFilter = true;
    }
    if (clientCodeFilter) {
      filter.clientCode = [];
      if (clientCodeFilterValue.length > 0) {
        filter = [...filter, { "field": "clientCode", "value": clientCodeFilterValue.map((e) => { return e.clientCode }), "op": "in" }];
        isFilter = true;
      }
    }
    if (statusFilter) {
      if (statusFilterValue.length > 0) {
        filter = [...filter, { "field": "currentStatus", "value": statusFilterValue.map((e) => { return e.value }), "op": "in" }];
        isFilter = true;
      }
    }

    if (documentTypeFilter) {
      if (documentTypeFilterValue.length > 0) {
        filter = [...filter, { "field": "documentType", "value": documentTypeFilterValue.map((e) => { return e.value }), "op": "in" }];
        isFilter = true;
      }
    }

    let cfromDate = "";
    let ctoDate = "";
    if (claimDateRangeFilterValueFrom && claimDateRangeFilterValueTo) {
      cfromDate = claimDateRangeFilterValueFrom;
      ctoDate = claimDateRangeFilterValueTo;

      if (moment(ctoDate).isBefore(cfromDate)) {
        EventService.emit("showError", {
          message:
            "Please enter a valid range (to date must be greater than the from date)",
        });
        return;
      }

      filter = [...filter, { "field": "createdAt", "value": { "fromDate": cfromDate, "toDate": ctoDate }, "op": "dateBetween" }];
      isFilter = true;
    }

    let ufromDate = "";
    let utoDate = "";
    if (claimUpdatedDateRangeFilterValueFrom && claimUpdatedDateRangeFilterValueTo) {
      ufromDate = claimUpdatedDateRangeFilterValueFrom;
      utoDate = claimUpdatedDateRangeFilterValueTo;

      if (moment(utoDate).isBefore(ufromDate)) {
        EventService.emit("showError", {
          message:
            "Please enter a valid range (to date must be greater than the from date)",
        });
        return;
      }

      filter = [...filter, { "field": "updatedAt", "value": { "fromDate": ufromDate, "toDate": utoDate }, "op": "dateBetween" }];
      isFilter = true;
    }

    let invfromDate = "";
    let invtoDate = "";
    if (invoiceDateRangeFilterValueFrom && invoiceDateRangeFilterValueTo) {
      invfromDate = invoiceDateRangeFilterValueFrom;
      invtoDate = invoiceDateRangeFilterValueTo;

      if (moment(invtoDate).isBefore(invfromDate)) {
        EventService.emit("showError", {
          message:
            "Please enter a valid range (to date must be greater than the from date)",
        });
        return;
      }

      filter = [...filter, { "field": "invoiceDate", "value": { "fromDate": invfromDate, "toDate": invtoDate }, "op": "dateBetween" }];
      isFilter = true;
    }

    // if (isFilter) {
    //   pageNum = 1;
    // }

    let counterFilter = 0;
    Object.keys(filter).forEach((ke) => {
      counterFilter = counterFilter + filter[ke].length;
    });

    if (counterFilter === 0) {
      isFilter = false;
    }

    navigoAPIServices.getAllClaims(
      30,
      pageNum,
      (res) => {
        if (
          res &&
          res.claims &&
          Array.isArray(res.claims) &&
          res.claims.length > 0
        ) {
          let allList = res.claims.map((appoint) => {
            return { ...appoint };
          });
          let localNumFrom = 1;
          let localNumTo = Math.min(30, res.totalCount);

          if (type === 1) {
            localNumFrom = 1;
            localNumTo = Math.min(30, res.totalCount);
          } else if (type === 2) {
            if (btnType === 0) {
              localNumTo = numFrom - 1;
              localNumFrom = Math.max(numFrom - 30, 1);
            } else {
              localNumFrom = numTo + 1;
              localNumTo = Math.min(numTo + 30, res.totalCount);
            }
          } else if (type === 3) {
            localNumFrom = numFrom;
            localNumTo = numTo;
          }
          console.log("localNumFrom", localNumFrom, "localNumTo", localNumTo);
          setAppointList([...allList]);
          setNoAppointment(false);
          setNumFrom(localNumFrom);
          setNumTo(localNumTo);
          setTotalCount(res.totalCount);
          setLoading(false);
          setCurrentPage(pageNum);
        } else {
          setAppointList([]);
          setNoAppointment(true);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
        setNoAppointment(true);
        EventService.emit("showError", {
          message: "Cannot retrieve claims",
        });
      },
      isFilter ? encodeURIComponent(JSON.stringify(filter)) : "",
      cfromDate,
      ctoDate
    );
  };

  const getStatusName = (i) => {
    if (i == 1) {
      return "Recieved";
    } else if (i == 2) {
      return "Approved";
    } else if (i == 3) {
      return "Rejected";
    } else if (i == 4) {
      return "Processed";
    } else if (i == 5) {
      return "Appealed";
    } else if (i == 6) {
      return "On Hold";
    } else {
      return "NA";
    }
  };

  const handleCompleteStatusFlow = (e, ele) => {
    appointValue.current = e.target.value;
    appointComplete.current = { ...ele };
  };
  const handleSelectChange = (e, ele) => {
    // console.log("inside select function");
    // if (ele.currentStatus === 2) {
    //   const intClaim = parseInt(approveClaim);
    //   const payload = {
    //     walletId: ele.walletId,
    //     statusUpdate: {
    //       id: 2,
    //       approvedClaimAmount: intClaim,
    //       employeeName: ele.patientFullName,
    //     },
    //   };
    //   navigoAPIServices.updateClaim(ele.id, payload,
    //     (response) => {
    //       console.log("Claim updated successfully:", response);
    //       setApproveModal(false);
    //       getAllClaims(1);
    //     },
    //     (error) => {
    //       console.error("Error updating claim:", error);
    //     }
    //   );
    // } else if (ele.currentStatus === 3) {
    //   const payload = {
    //     walletId: ele.walletId,
    //     statusUpdate: {
    //       id: 3,
    //       comment: reason,
    //       employeeName: ele.patientFullName,
    //     },
    //   };
    //   navigoAPIServices.updateClaim(ele.id, payload,
    //     (response) => {
    //       console.log("Claim updated successfully:", response);
    //       setRejectModal(false);
    //       getAllClaims(1);
    //     },
    //     (error) => {
    //       console.error("Error updating claim:", error);
    //     }
    //   );
    // }
  };

  const deductMoney = (id, walletId, approveClaim, approveRemarks) => {
    setIsClicked(true);
    const intClaim = parseInt(approveClaim);
    let user = authenticationService.getUserProfile();
    let fName = user.employeeProfile.firstName;
    let lName = user.employeeProfile.lastName;
    const payload = {
      walletId: walletId,
      statusUpdate: {
        id: 2,
        approvedClaimAmount: intClaim,
        employeeName: `${fName} ${lName ? lName : ""}`,
        comment: approveRemarks,
      },
    };
    navigoAPIServices.updateClaim(
      id,
      payload,
      (response) => {
        console.log("Claim updated successfully:", response);
        setIsClicked(false);
        setApproveModal(false);
        getAllClaims(1, 0, currentPage);
      },
      (error) => {
        console.error("Error updating claim:", error);
        setIsClicked(false);
        setIsContinueClicked(false);
        EventService.emit("showError", {
          message:
            error?.message ?? "Something went wrong while updating the claim status.",
        });
      }
    );
  };

  const processedClaim = (id, walletId, transactionId, processRemarks) => {
    let user = authenticationService.getUserProfile();
    let fName = user.employeeProfile.firstName;
    let lName = user.employeeProfile.lastName;
    const payload = {
      walletId: walletId,
      statusUpdate: {
        id: 4,
        transactionId: transactionId,
        employeeName: `${fName} ${lName ? lName : ""}`,
        comment: processRemarks,
      },
    };
    navigoAPIServices.updateClaim(
      id,
      payload,
      (response) => {
        console.log("Claim updated successfully:", response);
        setProcessedModal(false);
        getAllClaims(1, 0, currentPage);
      },
      (error) => {
        console.error("Error updating claim:", error);
      }
    );
  };

  const getAllClaimTypes = (pid) => {
    navigoAPIServices.getAllClaimTypes(
      pid,
      (res) => {
        if (
          res && res?.reimbursementTypes && res?.reimbursementTypes.length > 0
        ) {

          setEditClaimTypeClaimTypesArr(res.reimbursementTypes);

        }
        if (
          res && res?.claimConfig
        ) {

          setEditClaimTypeClaimConfig(res.claimConfig);

        }
      },
      (err) => {
        setLoading(false);
        EventService.emit("showError", {
          message: "Cannot retrieve claim types",
        });
      },
    );
  };

  const getAllFamilyMembers = (pid) => {
    commonServices.getAllFamilyMembers(
      pid,
      (res) => {
        if (
          res && res?.families
        ) {

          let responseWithRelations = [];
          for (let index = 0; index < res?.families.length; index++) {
            let element = res?.families[index];
            relations.forEach((e) => {
              if (e.code == element.relation) {
                element["relationString"] = `(${e.value})`;
              }
            })
            responseWithRelations = [...responseWithRelations, element]
          }
          setFileClaimFamilyMembersArr(
            responseWithRelations
          );
        }
      },
      (err) => {
        setLoading(false);
        EventService.emit("showError", {
          message: "Cannot retrieve claim types",
        });
      },
    );
  };

  const getAllRelations = (pid) => {
    patientService.getAllRelations(
      (res) => {
        if (
          res
        ) {
          setRelations(res);
        }
      },
      (err) => {
        setLoading(false);
        EventService.emit("showError", {
          message: "Cannot retrieve claim types",
        });
      },
    );
  };


  const handleIsValidClaimTypeChange = () => {
    setIsValidClaimTypeChange(Boolean(editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.typeId != editClaimFormFields?.reimbursementTypeId) && (claimLimitExceptionCheckbox || (Boolean(editClaimTypeLimitRes?.unlimited == true) || (editClaimFormFields?.claimAmount <= editClaimTypeLimitRes?.available))) && (editClaimTypeReason != "")));
  }

  const handleIsValidClaimFile = () => {
    setIsValidClaimFile(Boolean(claimFileFormFields && claimFileFormFields?.pid && claimFileFormFields?.patientName && claimFileFormFields?.clientCode && (claimFileFormFields?.claimedFor || claimFileFormFields?.claimedFor == 0) && claimFileFormFields?.reimbursementTypeId && claimFileFormFields?.claimAmount && (claimFileFormFields?.ignoreReimbursementLimit || (Boolean(editClaimTypeLimitRes?.unlimited == true) || (claimFileFormFields?.claimAmount <= editClaimTypeLimitRes?.available))) && claimFileFormFields?.invoiceDate && ((editClaimTypeClaimConfig && editClaimTypeClaimConfig?.documentType && editClaimTypeClaimConfig?.documentType?.heading && editClaimTypeClaimConfig?.documentType?.heading != "") ? (claimFileFormFields?.documentType || documentDataWithStatus?.length <= 0) : true)));
  }

  const getClaimTypeLimit = (pid, typeId) => {
    navigoAPIServices.getClaimTypeLimit(
      pid,
      typeId,
      (res) => {
        if (
          res
        ) {
          setEditClaimTypeLimitRes(res);
          // handleIsValidClaimTypeChange(res);
        }
      },
      (err) => {
        setLoading(false);
        EventService.emit("showError", {
          message: "Cannot retrieve claim type limit",
        });
      },
    );
  };

  const onHoldClaim = (id, walletId, onHoldRemarks, onHoldUploadDocumentsAgainCheckbox) => {
    setIsClicked(true);
    let user = authenticationService.getUserProfile();
    let fName = user.employeeProfile.firstName;
    let lName = user.employeeProfile.lastName;
    const payload = {
      walletId: walletId,
      statusUpdate: {
        id: 6,
        employeeName: `${fName} ${lName ? lName : ""}`,
        comment: onHoldRemarks,
        uploadDocumentsAgain: onHoldUploadDocumentsAgainCheckbox ?? false,
      },
    };
    navigoAPIServices.updateClaim(
      id,
      payload,
      (response) => {
        setIsClicked(false);
        console.log("Claim updated successfully:", response);
        setOnHoldModal(false);
        resetFormFields();
        getAllClaims(1, 0, currentPage);
        EventService.emit("showError", {
          message: "Claim status updated successfully",
        });
      },
      (error) => {
        setIsClicked(false);
        console.error("Error updating claim:", error);
        resetFormFields();
        EventService.emit("showError", {
          message: error?.message ?? "Error while updating claim status",
        });
      }
    );
  };

  const updateClaimType = (id, walletId, typeId, ignoreReimbursementLimit, reason) => {
    setIsClicked(true);
    let user = authenticationService.getUserProfile();
    const payload = {
      walletId: walletId,
      reimbursementTypeUpdate: {
        typeId: typeId,
        ignoreReimbursementLimit: ignoreReimbursementLimit,
        employeeUserId: user.id,
        reason: reason,
      },
    };
    navigoAPIServices.updateClaimType(
      id,
      payload,
      (response) => {
        setIsClicked(false);
        handleCloseModal();
        getAllClaims(1, 0, currentPage);
        EventService.emit("showError", {
          message: "Claim type updated successfully",
        });
      },
      (error) => {
        setIsClicked(false);
        EventService.emit("showError", {
          message: error?.message ?? "Error while updating claim type",
        });
      }
    );
  };

  const rejectClaim = (id, walletId, reason, remarks) => {
    let user = authenticationService.getUserProfile();
    let fName = user.employeeProfile.firstName;
    let lName = user.employeeProfile.lastName;
    const payload = {
      walletId: walletId,
      statusUpdate: {
        id: 3,
        comment: remarks,
        employeeName: `${fName} ${lName ? lName : ""}`,
        rejectReason: reason,
      },
    };
    navigoAPIServices.updateClaim(
      id,
      payload,
      (response) => {
        console.log("Claim updated successfully:", response);
        setRejectModal(false);
        getAllClaims(1, 0, currentPage);
      },
      (error) => {
        console.error("Error updating claim:", error);
      }
    );
  };

  const appealedClick = (id, walletId) => {
    let user = authenticationService.getUserProfile();
    let fName = user.employeeProfile.firstName;
    let lName = user.employeeProfile.lastName;
    const payload = {
      walletId: walletId,
      statusUpdate: {
        id: 5,
        employeeName: `${fName} ${lName ? lName : ""}`,
      },
    };
    navigoAPIServices.updateClaim(
      id,
      payload,
      (res) => {
        console.log("updated successfully");
        getAllClaims(1, 0, currentPage);
      },
      (err) => {
        console.log("not able to update");
      }
    );
  };

  const getDocumentFormat = (type) => {
    switch (type) {
      case "jpg":
      case "jpeg":
        return 1;
      case "png":
        return 2;
      case "gif":
        return 3;
      case "pdf":
        return 4;
      case "mp3":
        return 5;
      case "mp4":
        return 6;
      default:
        return 0;
    }
  };

  const handleUploadDocumentClick = () => {
    if (uploadDocumentRef && uploadDocumentRef.current) {
      uploadDocumentRef.current.click();
    }
  };

  const createReimbursement = () => {

    let allDocsUploaded = true;
    let docsIds = [];
    documentDataWithStatus.forEach((e) => {
      if (e.status != 'uploaded') {
        allDocsUploaded = false;
      }
      docsIds = [...docsIds, e.uploadedDocId]
    })

    if (allDocsUploaded) {
      setIsClicked(true);
      const payload = {
        claimAmount: parseInt(claimFileFormFields?.claimAmount),
        documentList: docsIds,
        reimbursementTypeName: claimFileFormFields?.reimbursementTypeName,
        reimbursementTypeId: claimFileFormFields?.reimbursementTypeId,
        invoiceDateString: claimFileFormFields?.invoiceDate,
        ignoreReimbursementLimit: claimFileFormFields?.ignoreReimbursementLimit,
        documentType: claimFileFormFields?.documentType ?? 0,
        claimedFor: claimFileFormFields?.claimedFor
      };


      navigoAPIServices.createReimbursementClaim(
        claimFileFormFields.pid,
        payload,
        (res) => {
          // console.log(JSON.stringify(res));
          setIsClicked(false);
          getAllClaims(1, 0, currentPage);
          handleCloseModal();
          EventService.emit("showError", {
            message: "Claim filed successfully",
          });
        },
        (err) => {
          setIsClicked(false);
          EventService.emit("showError", {
            message: err?.message ?? "Something went wrong while filing the claim.",
          });
        },
      );
    }
  }

  const handleApplyForClaim = () => {
    setIsClicked(true);

    // make sure to validate form fields before proceeding
    if (!isValidClaimFile) {
      setIsClicked(false);
      EventService.emit("showError", {
        message: "Invalid Claim Fields.",
      });
      return;
    }

    for (let i = 0; i < documentDataWithStatus.length; i += 1) {
      try {

        if (documentDataWithStatus[i].status == 'uploaded') {
          continue;
        }
        updateDocumentDataWithStatusFields(i, { status: 'uploading' });
        const file = documentDataWithStatus[i].fileObject;
        const fileExtension = file.name.split(".").pop();

        let document = {
          title: file.name,
          description: "",
          url: "",
          type: 12,
          format: getDocumentFormat(fileExtension),
          status: 4,
        };

        navigoAPIServices.uploadDocument(
          file,
          file.name,
          document,
          (res) => {
            updateDocumentDataWithStatusFields(i, { uploadedDocId: res?.id, status: 'uploaded' });
          },
          (err) => {
            setIsClicked(false);
            updateDocumentDataWithStatusFields(i, { status: 'error' });
            EventService.emit("showError", {
              message: err?.message ?? "Something went wrong while uploading documents.",
            });
          },
          claimFileFormFields.pid
        );
      } catch (err) {
        setIsClicked(false);
        for (let index = 0; index < documentDataWithStatus.length; index++) {
          if (documentDataWithStatus[index].status == 'uploading') {
            updateDocumentDataWithStatusFields(index, { status: 'error' });
          }
        }
        EventService.emit("showError", {
          message: "Something went wrong.",
        });
      }
    }


    // createReimbursement();

  };

  const openDocumentInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const handleUploadReportClick = () => {
    appointComplete.current = { ...menuFor };
    setFileReport(false);
    if (uploadFileRef && uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };
  const handleUploadFile = async (e) => {
    if (e.target.files.length === 0 || uploading) return;

    setUploading(true);
    setDialogType(4);

    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop();

    let document = {
      title: file.name,
      description: "",
      url: "",
      type: 12,
      format: getDocumentFormat(fileExtension),
      status: 4,
    };

    try {
      const res = await new Promise((resolve, reject) => {
        navigoAPIServices.uploadDocument(
          file,
          file.name,
          document,
          (res) => {
            if (res && res.id) {
              resolve(res);
            } else {
              reject("Invalid response from upload");
            }
          },
          (err) => {
            reject(err);
          },
          appointComplete.current.patientId
        );
      });

      const newDocumentId = res.id;

      const updatedDocumentList = Array.from(
        new Set([...menuFor.documentList, newDocumentId])
      );

      fileURL.current = res.url;
      setFileReport(true);
      setDialogType(2);
      setUploading(false);

      menuFor.documentList = updatedDocumentList;
      setDocumentData(updatedDocumentList);

      await updateDocumentList(
        menuFor.id,
        menuFor.walletId,
        updatedDocumentList
      );
      await getDocuments(updatedDocumentList);
    } catch (error) {
      console.error("Error during document upload or update:", error);
      setDialog(false);
      setDialogType(2);
      setUploading(false);
      EventService.emit("showError", {
        message:
          "Something went wrong while uploading or updating the document list.",
      });
    }
  };

  const searchPatientId = (e) => {
    setSearchDisabled("patientId");
    skip.current = 30;
    getAllClaims(1, 0, 1);
  };

  const searchPatientFullName = (e) => {
    setSearchDisabled("patientFullName");
    skip.current = 30;
    getAllClaims(1, 0, 1);
  };

  const searchClaimId = (e) => {
    setSearchDisabled("claimId");
    skip.current = 30;
    getAllClaims(1, 0, 1);
  };

  const getMinInvoiceDate = (minDaysInvoice) => {
    const today = new Date();
    const minDate = new Date(today.setDate(today.getDate() - minDaysInvoice));
    const formattedMinDate = minDate.toISOString().split('T')[0];
    return formattedMinDate;
  }

  const getMaxInvoiceDate = () => {
    const today = new Date();
    const formattedMaxDate = today.toISOString().split('T')[0];
    return formattedMaxDate;
  }

  const getTransaction = (id) => {
    navigoAPIServices.getTransaction(
      id,
      (res) => {
        setTransactionData(res.data);
      },
      (err) => {
        EventService.emit("showError", {
          message: "No transaction available",
        });
      }
    );
  };

  const updateDocumentList = (id, walletId, documentList) => {
    const payload = {
      walletId: walletId,
      documentListUpdate: documentList,
    };

    return new Promise((resolve, reject) => {
      navigoAPIServices.updateClaim(
        id,
        payload,
        (res) => {
          console.log("Updated the list successfully.");
          resolve(res);
        },
        (err) => {
          console.log("Error in updating the list.");
          reject(err);
        }
      );
    });
  };

  const getDocuments = async (documentList) => {
    if (!Array.isArray(documentList)) {
      console.error("documentList is not an array:", documentList);
      return;
    }

    try {
      const documentListData = await Promise.all(
        documentList.map((documentId) => {
          return new Promise((resolve, reject) => {
            navigoAPIServices.getDocuments(
              documentId,
              (res) => {
                console.log("Response for document ID:", documentId, res);
                resolve(res);
                setDocumentModal(true);
              },
              (err) => {
                console.error(`Error fetching document ID ${documentId}:`, err);
                reject(err);
              }
            );
          });
        })
      );

      setDocumentData(documentListData);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const getRemarks = (id) => {
    const cliamItem = appointList.find((appoint) => appoint.claimId == id);

    if (cliamItem) {
      const statusMap = {
        1: "Received",
        2: "Approved",
        3: "Rejected",
        4: "Processed",
        5: "Appealed",
        6: "On hold"
      };

      let remarksData = [];

      if (cliamItem.ticketEvents && cliamItem.ticketEvents.length > 0) {
        remarksData = cliamItem.ticketEvents
          .filter((event) => event.id !== 1 && event.id !== 5)
          .map((event) => ({
            status: statusMap[event.id],
            remarks: event.comment || "No remarks available.",
          }));
      }

      setData(remarksData);
    } else {
      setData([]);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const closeIcdModal = () => {
    setIcdModal(false);
    setClaimIcdReason([]);
    setClaimReasons([]);
  };
  const closeClaimModal = () => {
    setClaimModal(false);
    setIcdModal(false);
    setClaimIcdReason([]);
    setClaimReasons([]);
  };

  const closeRemarksModal = () => {
    setRemarksModal(false);
  };

  const closeDocumentModal = () => {
    setDocumentModal(false);
    setDocumentData([]);
  };

  const viewDocument = (url, token) => {
    if (url) {
      window.open(url+"?token="+token.toString(), "_blank");
    }
  };

  const handleInputChange = (setter, value, field) => {
    setter(value);

    if (!value) {
      if (field === "patientId") {
        setPatientId();
      } else if (field === "claimId") {
        setClaimId();
      } else if (field === "patientFullName") {
        setPatientFullName();
      }
      if (searchDisabled === field) {
        setSearchDisabled(null);
      }
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const searchIcd = debounce((text, index) => {
    if (text.length > 2) {
      let onResponse = (data) => {
        const updatedClaimIcdReason = [...claimIcdReason];
        if (!updatedClaimIcdReason[index]) {
          updatedClaimIcdReason[index] = {
            code: "",
            label: "",
            suggestions: [],
            change: false,
          };
        }

        // Store both code and label for suggestions
        updatedClaimIcdReason[index].suggestions = data.map((item) => ({
          code: item.value,
          label: item.label,
        }));

        updatedClaimIcdReason[index].change = true;
        setClaimIcdReason(updatedClaimIcdReason);
      };

      let onError = (error) => {
        console.error("Error fetching ICD suggestions", error);
      };

      navigoAPIServices.getAuto(text, onResponse, onError);
    } else {
      const updatedClaimIcdReason = [...claimIcdReason];

      if (!updatedClaimIcdReason[index]) {
        updatedClaimIcdReason[index] = {
          code: "",
          label: "",
          suggestions: [],
          change: false,
        };
      }

      updatedClaimIcdReason[index].suggestions = [];
      updatedClaimIcdReason[index].change = false;
      setClaimIcdReason(updatedClaimIcdReason);
    }
  }, 300);

  const handleChiefComplaintChange = (e, index, suggestion) => {
    const newClaimIcdReason = [...claimIcdReason];

    if (!newClaimIcdReason[index]) {
      newClaimIcdReason[index] = {
        code: "",
        label: "",
        suggestions: [],
        change: false,
      };
    }
    newClaimIcdReason[index].label = e.target.value;
    newClaimIcdReason[index].change = true;
    setClaimIcdReason(newClaimIcdReason);

    searchIcd(e.target.value, index);
  };

  const handleClaimDescriptionChange = (e) => {
    setClaimReasons(e.target.value);
  };

  const handleSelectSuggestion = (index, suggestion) => {
    const updatedClaimIcdReason = [...claimIcdReason];

    updatedClaimIcdReason[index].label = suggestion.label;
    updatedClaimIcdReason[index].code = suggestion.code;
    updatedClaimIcdReason[index].change = true;
    updatedClaimIcdReason[index].suggestions = [];

    setClaimIcdReason(updatedClaimIcdReason);
  };

  const addNewField = () => {
    setClaimIcdReason([
      ...claimIcdReason,
      { code: "", label: "", suggestions: [], change: false },
    ]);
  };

  const isContinueEnabled = () => {
    return claimIcdReason.some(
      (reason) => reason.label && reason.label.trim().length > 0
    );
  };

  const handleOpenClaimFileDialog = () => {
    setOpenClaimFileDialog(true);

  }

  useEffect(() => {
    if (!patientId && !claimId && !patientFullName && componentMounted) {
      getAllClaims(1, 0, currentPage);
    }
  }, [patientId, claimId, patientFullName]);

  useEffect(() => {
    if (triggerClaimsFetch) {
      getAllClaims(1, 0, currentPage);
      setTriggerClaimsFetch(false);
    }
  }, [triggerClaimsFetch]);

  const handleRejectClick = (id, walletId) => {
    setId(id);
    setWalletId(walletId);
    setRejectModal(true);
    resetFormFields();
  };

  const handleAppealedClick = (id, walletId) => {
    setId(id);
    setWalletId(walletId);
    appealedClick(id, walletId);
    resetFormFields();
  };

  const handleProcessedClick = (id, walletId) => {
    setId(id);
    setWalletId(walletId);
    setProcessedModal(true);
    resetFormFields();
  };
  const handleOnHoldClick = (id, walletId, ticketEvents) => {
    setId(id);
    setWalletId(walletId);
    setClickedClaimTicketEvents(ticketEvents ?? []);
    setOnHoldModal(true);
    resetFormFields();
  };

  const handleApproveModal = (id, walletId) => {
    setId(id);
    setWalletId(walletId);
    setApproveModal(true);
    resetFormFields();
  };

  const handleCloseModal = () => {
    setRejectModal(false);
    setApproveModal(false);
    setProcessedModal(false);
    setOpenClaimFileDialog(false);
    setOnHoldModal(false);
    setOpenEditClaimTypeDialog(false);
    resetFormFields();
  };

  const handleClaimReason = (id) => {
    if (isContinueClicked) {
      const filteredIcdValues = claimIcdReason.filter(
        (reason) => reason.code?.trim() && reason.label?.trim()
      );

      if (filteredIcdValues.length === 0) {
        console.log("No valid ICD values to submit");
        return;
      }

      const claimReasonUpdate = {
        walletId: menuFor.walletId,
        claimReason: claimReasons,
        icdValues: filteredIcdValues.map((reason) => ({
          code: reason.code,
          label: reason.label,
        })),
      };

      const requestBody = {
        claimReasonUpdate,
      };

      navigoAPIServices.updateClaim(
        id,
        requestBody,
        (res) => {
          console.log("Claim reason and ICD values updated successfully", res);
          closeClaimModal();
          getAllClaims(1, 0, currentPage);
        },
        (err) => {
          console.error("Failed to update claim reason and ICD values", err);
        }
      );
    } else {
      setIsContinueClicked(true);
    }
  };

  const handleContinue = (id) => {
    if (reason) {
      setIsContinueClicked(true);
    } else if (approveClaim) {
      setIsContinueClicked(true);
    } else if (claimReasons) {
      setIsContinueClicked(true);
    } else if (processClaim) {
      setIsContinueClicked(true);
    }
  };

  const getPastDate = (monthsAgo) => {
    const today = new Date();
    return new Date(today.setMonth(today.getMonth() - monthsAgo));
  };

  const filteredTransactions = transactionData.filter((transaction) => {
    const transactionDate = new Date(transaction.createdAt);
    if (selectedFilter === "3months") {
      return transactionDate >= getPastDate(3);
    } else if (selectedFilter === "6months") {
      return transactionDate >= getPastDate(6);
    } else if (selectedFilter === "1year") {
      return transactionDate >= getPastDate(12);
    }
    return true;
  });


  const isFormFilled = (reason && (remarks != null && remarks != "")) || approveClaim || processClaim || (onHoldRemarks != null && onHoldRemarks != "");
  const handleBackClick = () => {
    props.history.push("/miscellaneous");
  };

  const resetFormFields = () => {
    setProcessClaim("");
    setProcessRemarks("");
    setReason("");
    setRemarks("");
    setApproveClaim("");
    setApproveRemarks("");
    setOnHoldRemarks("");
    setIsContinueClicked(false);
    setIsValidClaimFile(false);
    setIsValidClaimTypeChange(false);
    setClaimFileFormFields({});
    setEditClaimFormFields({});
    setClaimLimitExceptionCheckbox(false);
    setRecheckEditClaimTypeCarefully(false);
    setEditClaimTypeClaimTypesArr([]);
    setEditClaimTypeClaimConfig({});
    setEditClaimTypeReason("");
    setEditClaimTypeLimitRes(null);
    setIsFileClaimContinueClicked(false);
    setDocumentDataWithStatus([]);
  };

  const handleClearFilters = () => {
    setClaimDateRangeFilterFrom("");
    setClaimUpdatedDateRangeFilterFrom("");
    setClaimUpdatedDateRangeFilterTo("");
    setClaimDateRangeFilterTo("");
    setInvoiceDateRangeFilterFrom("");
    setInvoiceDateRangeFilterTo("");
    setClaimUpdatedDateRangeFilter(false);
    setCurrentPage(1);
    setTriggerClaimsFetch(true);
  };

  const handleDateOfInvoiceClick = () => {
    if (dateOfInvoiceFieldRef && dateOfInvoiceFieldRef?.current) {
      dateOfInvoiceFieldRef.current.click();
    }
  }

  return (
    <div className="navigo-appoint-container">
      <div className="navigo-appoint-section-3">
        <div className="navigo-appoint-section-1" onClick={handleBackClick}>
          <FontIcon>arrow_back</FontIcon>
        </div>
        <h1 className="navigo-appoint-section-2">Reimbursement</h1>
        <div className="navigo-appoint-section-4">
          <div className="navigo-appoint-section-4-bottom">
            <div className="navigo-appoint-section-4-field">
              <TextField
                type="number"
                className="navigo-appoint-section-4-textfield"
                placeholder="Patient ID"
                value={patientId}
                onChange={(event) =>
                  handleInputChange(
                    setPatientId,
                    event.target.value,
                    "patientId"
                  )
                }
                disabled={searchDisabled && searchDisabled !== "patientId"}
              />
              <button
                className="navigo-appoint-section-4-bottom-button"
                disabled={
                  !patientId ||
                  (searchDisabled && searchDisabled !== "patientId")
                }
                onClick={() => searchPatientId(patientId)}
              >
                Search
              </button>
            </div>

            <div className="navigo-appoint-section-4-field">
              <TextField
                className="navigo-appoint-section-4-textfield"
                placeholder="Claim ID"
                value={claimId}
                onChange={(event) =>
                  handleInputChange(setClaimId, event.target.value, "claimId")
                }
                disabled={searchDisabled && searchDisabled !== "claimId"}
              />
              <button
                className="navigo-appoint-section-4-bottom-button"
                disabled={
                  !claimId || (searchDisabled && searchDisabled !== "claimId")
                }
                onClick={() => searchClaimId()}
              >
                Search
              </button>
            </div>

            <div className="navigo-appoint-section-4-field">
              <TextField
                className="navigo-appoint-section-4-textfield"
                placeholder="Patient name"
                value={patientFullName}
                onChange={(event) =>
                  handleInputChange(
                    setPatientFullName,
                    event.target.value,
                    "patientFullName"
                  )
                }
                disabled={searchDisabled && searchDisabled !== "patientFullName"}
              />
              <button
                className="navigo-appoint-section-4-bottom-button"
                disabled={
                  !patientFullName ||
                  (searchDisabled && searchDisabled !== "patientFullName")
                }
                onClick={() => searchPatientFullName(patientFullName)}
              >
                Search
              </button>
            </div>
          </div>
        </div>


        <div className="navigo-appoint-section-4-bottom-button-container">
          <button
            className="navigo-appoint-section-4-bottom-button"
            onClick={handleOpenClaimFileDialog}
          >
            + File new claim
          </button>
        </div>

      </div>

      <div className="navigo-appoint-section-5">
        <div className="navigo-appoint-section-5-grid-container-claim">
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Claim <br />
              ID
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Patient <br />
              ID
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Patient <br />
              name
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section">
            <p>
              Claim <br />
              type
              <br />
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section">
            <p>
              Claimed <br />
              for
              <br />
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Document <br />
              type
              <br />
            </p>
            <img
              src={documentTypeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(9);
              }}
            />
            {openFilter === 9 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={allDocumentTypesList}
                  value={documentTypeFilterValue}
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setDocumentTypeFilterValue(v);
                      setDocumentTypeFilter(true);
                    } else {
                      setDocumentTypeFilterValue([]);
                      setDocumentTypeFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Enter the document type(s) to filter"} />
                  )}
                />
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Invoice <br />
              date
            </p>
            <img
              src={invoiceDateRangeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(10);
              }}
            />
            {openFilter === 10 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <div className="navigo-appointment-date-range-filter">
                  <p>From</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                    onChange={(v) => {
                      if (v.target.value) {
                        setInvoiceDateRangeFilterFrom(v.target.value);
                      }
                    }}
                    value={invoiceDateRangeFilterValueFrom}
                  />
                </div>
                <div className="navigo-appointment-date-range-filter">
                  <p>To</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0], // Limit future dates
                    }}
                    onChange={(v) => {
                      if (v.target.value) {
                        setInvoiceDateRangeFilterTo(v.target.value);
                      }
                    }}
                    value={invoiceDateRangeFilterValueTo}
                  />
                </div>

                <Button className="" onClick={handleClearFilters}>
                  <FontIcon className="">close</FontIcon>
                </Button>
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Claim recieved <br />
              on
            </p>
            <img
              src={claimDateRangeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(8);
              }}
            />
            {openFilter === 8 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <div className="navigo-appointment-date-range-filter">
                  <p>From</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                    onChange={(v) => {
                      if (v.target.value) {
                        setClaimDateRangeFilterFrom(v.target.value);
                      }
                    }}
                    value={claimDateRangeFilterValueFrom}
                  />
                </div>
                <div className="navigo-appointment-date-range-filter">
                  <p>To</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0], // Limit future dates
                    }}
                    onChange={(v) => {
                      if (v.target.value) {
                        setClaimDateRangeFilterTo(v.target.value);
                      }
                    }}
                    value={claimDateRangeFilterValueTo}
                  />
                </div>

                <Button className="" onClick={handleClearFilters}>
                  <FontIcon className="">close</FontIcon>
                </Button>
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Claim updated <br />
              on
            </p>
            <img
              src={claimUpdatedDateRangeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(11);
              }}
            />
            {openFilter === 11 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <div className="navigo-appointment-date-range-filter">
                  <p>From</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                    onChange={(v) => {
                      if (v.target.value) {
                        setClaimUpdatedDateRangeFilterFrom(v.target.value);
                      }
                    }}
                    value={claimUpdatedDateRangeFilterValueFrom}
                  />
                </div>
                <div className="navigo-appointment-date-range-filter">
                  <p>To</p>
                  <TextField
                    type="date"
                    style={{ width: "90%" }}
                    className="dob"
                    id="dob"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0], // Limit future dates
                    }}
                    onChange={(v) => {
                      if (v.target.value) {
                        setClaimUpdatedDateRangeFilterTo(v.target.value);
                      }
                    }}
                    value={claimUpdatedDateRangeFilterValueTo}
                  />
                </div>

                <Button className="" onClick={handleClearFilters}>
                  <FontIcon className="">close</FontIcon>
                </Button>
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Claim amount <br />
              filed
            </p>
          </div>

          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Claim amount approved <br />
            </p>
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>
              Available wallet
              <br />
              balance
            </p>
          </div>

          <div className="navigo-appoint-section-5-grid-container-section-7 navigo-appoint-section-5-grid-container-common-section">
            <p>
              Client <br />
              code
            </p>
            <img
              src={clientCodeFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(4);
                // setOpenClientCodeFilter(true);
              }}
            />
            {openFilter === 4 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={clientCodeList}
                  value={clientCodeFilterValue}
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setClientCodeFilterValue(v);
                      setClientCodeFilter(true);
                    } else {
                      setClientCodeFilterValue([]);
                      setClientCodeFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.clientName + " (" + option.clientCode + ")";
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"Enter the client code to filter"}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className="navigo-appoint-section-5-grid-container-common-section ">
            <p>Status</p>
            <img
              src={statusFilter ? filterApplied : funnelIcon}
              onClick={() => {
                setOpenBackdrop(true);
                setOpenFilter(5);
              }}
            />
            {openFilter === 5 && (
              <div className="navigo-appoint-section-5-grid-container-section-common-filter-popup">
                <Autocomplete
                  multiple
                  id="appointment-dialog-client-code-filter"
                  className="appointment-dialog-client-code-filter-class"
                  options={allStatusList}
                  value={statusFilterValue} //statusFilterValue
                  onChange={(e, v) => {
                    if (v.length > 0) {
                      setStatusFilterValue(v);
                      setStatusFilter(true);
                    } else {
                      setStatusFilterValue([]);
                      setStatusFilter(false);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  // filterOptions={(x) => x}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={"Type status name"} />
                  )}
                />
              </div>
            )}
          </div>
          <div></div>
        </div>
      </div>
      <div className="navigo-appoint-section-6">
        {appointList && appointList.length > 0 && (
          <div className="navigo-appoint-bottom-bar">
            <Button
              className="navigo-appoint-bottom-bar-button"
              disabled={numFrom === 1 || loading}
            >
              <FontIcon
                className="navigo-count-icon"
                onClick={() => getAllClaims(2, 0, currentPage - 1)}
              >
                arrow_drop_up
              </FontIcon>
            </Button>
            <div className="navigo-appoint-bottom-bar-text">
              Showing
              <span
                style={{
                  color: "#ef7c01",
                }}
              >
                {` ${numFrom}-${numTo} `}
              </span>
              <span>of</span>
              <span>{` ${totalCount}`}</span>
            </div>
            <Button
              className="navigo-appoint-bottom-bar-button"
              disabled={numTo === totalCount || loading}
            >
              <FontIcon
                className="navigo-count-icon"
                onClick={() => getAllClaims(2, 1, currentPage + 1)}
              >
                arrow_drop_down
              </FontIcon>
            </Button>
          </div>
        )}
        <div className="navigo-appoint-section-6-list">
          {appointList &&
            appointList.map((ele) => {
              return (
                <div
                  className="navigo-appoint-section-6-grid-container-claim"
                  key={ele.claimId}
                >
                  <div className="general-font-class ">
                    {ele.claimId ? ele.claimId : "-"}
                  </div>
                  <div className="general-font-class ">
                    {ele.patientId ? ele.patientId : "-"}
                  </div>
                  <div className="general-font-class general-cursor-pointer navigo-appointment-break-word-class">
                    {ele.patientFullName ? ele.patientFullName : "-"}
                  </div>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {ele.reimbursementTypeName
                      ? ele.reimbursementTypeName
                      : "-"}
                  </div>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {(ele.claimedFor == null || ele.claimedFor == undefined) ? "-"
                      : ((ele?.claimedFor == 0) ? "Self" : `${ele.claimedForName} (${ele.claimedForRelationName ?? ""})`)
                    }
                  </div>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {(ele.documentType == null || ele.documentType == undefined) ? "-"
                      : ((ele?.documentType == 1) ? "Softcopy" : ((ele?.documentType == 2) ? "Hardcopy" : "-"))
                    }
                  </div>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {(ele.invoiceDate == null || ele.invoiceDate == undefined || ele.invoiceDate == "" || ele.invoiceDate == "0001-01-01T00:00:00Z") ? "-"
                      : (moment.utc(ele.invoiceDate).format("DD/MM/YYYY"))
                    }
                  </div>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {/* {moment(ele.createdAt).toLocaleString().format("DD/MM/YYYY")} */}
                    {moment.utc(ele.createdAt).format("DD/MM/YYYY")}
                  </div>
                  <div className="general-font-class navigo-appointment-break-word-class">
                    {/* {moment(ele.createdAt).toLocaleString().format("DD/MM/YYYY")} */}
                    {(ele.updatedAt == ele.createdAt) ? "-" : moment.utc(ele.updatedAt).format("DD/MM/YYYY")}
                  </div>
                  <div className="general-font-class">
                    {ele.claimAmount ? ele.claimAmount : "-"}
                  </div>
                  <div className="general-font-class">
                    {ele.approvedClaimAmount ? ele.approvedClaimAmount : "-"}
                  </div>
                  <div className="general-font-class">
                    {ele.unlimitedWallet ? "Unlimited" : ((ele.currentWalletBalance != null && ele.currentWalletBalance != undefined) ? ele.currentWalletBalance : "-")}
                  </div>
                  <div className="general-font-class">
                    {ele.clientCode ? ele.clientCode : "-"}
                  </div>
                  <div className="navigo-appoint-section-6-grid-status">
                    {ele.currentStatus !== 7 ? (
                      <Select
                        labelId="navigo-appoint-status-select"
                        id="navigo-appoint-status-select-id"
                        value={ele.currentStatus}
                        onChange={(e) => {
                          if (e.target.value === 4) {
                            handleCompleteStatusFlow(e, ele);
                            console.log("2222", e.target.value, ele);
                          } else {
                            handleSelectChange(e.target.value, ele);
                            console.log("1111", e.target.value, ele);
                          }
                        }}
                        label="Status"
                        className={`navigo-dashboard-select-css navigo-status-selector ${(ele.currentStatus === 2 || ele.currentStatus === 6)
                          ? "pending-class"
                          : `${ele.currentStatus === 3
                            ? "invalid-class"
                            : `${ele.currentStatus === 4
                              ? "closed-class"
                              : ele.currentStatus === 1
                                ? "progress-class"
                                : "progress-class"
                            }`
                          }`
                          }`}
                      >
                        <MenuItem
                          value={1}
                          style={{
                            borderBottom: "1px solid #d8d8d8",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          disabled={
                            ele.currentStatus == 4 ||
                            ele.currentStatus == 2 ||
                            ele.currentStatus == 5 ||
                            ele.currentStatus == 3 ||
                            ele.currentStatus == 1 ||
                            ele.currentStatus == 6
                          }
                        >
                          Received
                        </MenuItem>

                        <MenuItem
                          value={2}
                          style={{
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          onClick={() =>
                            handleApproveModal(ele.id, ele.walletId)
                          }
                          disabled={
                            ele.currentStatus == 4 ||
                            ele.currentStatus == 3 ||
                            ele.currentStatus == 2
                          }
                        >
                          Approved
                        </MenuItem>

                        <MenuItem
                          value={3}
                          style={{
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          onClick={() =>
                            handleRejectClick(ele.id, ele.walletId)
                          }
                          disabled={
                            ele.currentStatus == 4 ||
                            ele.currentStatus == 2 ||
                            ele.currentStatus == 3
                          }
                        >
                          Rejected
                        </MenuItem>

                        <MenuItem
                          value={4}
                          style={{
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          disabled={
                            ele.currentStatus == 4 ||
                            ele.currentStatus == 5 ||
                            ele.currentStatus == 3 ||
                            ele.currentStatus == 1 ||
                            ele.currentStatus == 6
                          }
                          onClick={() => {
                            handleProcessedClick(ele.id, ele.walletId);
                          }}
                        >
                          Processed
                        </MenuItem>
                        <MenuItem
                          value={5}
                          style={{
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          onClick={() => {
                            handleAppealedClick(ele.id, ele.walletId);
                          }}
                          disabled={
                            ele.currentStatus == 4 ||
                            ele.currentStatus == 2 ||
                            ele.currentStatus == 1 ||
                            ele.currentStatus == 5 ||
                            ele.currentStatus == 6
                          }
                        >
                          Appealed
                        </MenuItem>
                        <MenuItem
                          value={6}
                          style={{
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                            fontFamily: "Montserrat",
                          }}
                          onClick={() => {
                            handleOnHoldClick(ele.id, ele.walletId, ele.ticketEvents);
                          }}
                          disabled={
                            !(ele.currentStatus == 1 ||
                              ele.currentStatus == 5 || ele.currentStatus == 6)
                          }
                        >
                          On Hold
                        </MenuItem>
                      </Select>
                    ) : (
                      <div>
                        <span>{getStatusName(ele.currentStatus)}</span>
                      </div>
                    )}
                  </div>

                  <IconButton
                    onClick={(e) => handleClick(e, ele)}
                    className="navigo-appoint-section-6-grid-edit-call"
                  >
                    <OptionsIcon />
                  </IconButton>
                </div>
              );
            })}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {
              <MenuItem
                onClick={() => {
                  setIcdModal(true);
                  setAnchorEl(null);
                }}
              >
                Claim reason
              </MenuItem>
            }
            <MenuItem
              onClick={() => {
                getDocuments(menuFor.documentList);
              }}
            >
              Claim documents
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenModal(true);
                getTransaction(menuFor.walletId);
                setAnchorEl(null);
              }}
            >
              Transaction history
            </MenuItem>
            {
              <MenuItem
                onClick={() => {
                  setRemarksModal(true);
                  getRemarks(menuFor.claimId);
                }}
              >
                Remarks
              </MenuItem>
            }
            <MenuItem
              onClick={() => {
                getAllClaimTypes(menuFor.patientId);
                setOpenEditClaimTypeDialog(true);
                setEditClaimTypeField(menuFor?.reimbursementTypeId);
                getClaimTypeLimit(menuFor?.patientId, menuFor?.reimbursementTypeId);
                updateEditClaimFormFields(menuFor);
                setAnchorEl(null);
              }}
            >
              Edit claim type
            </MenuItem>
          </Menu>
        </div>
        {loading && <GlobalSkeletonLoader />}
        {loading === false && noAppointment && (
          <div className="no-appointments-container">
            <p>No claims found!</p>
          </div>
        )}
      </div>

      {openBackdrop && (
        <div
          className="navigo-appointment-backdrop-class"
          onClick={() => {
            setOpenFilter(0);
            setOpenBackdrop(false);
          }}
        ></div>
      )}

      {openModal && (
        <Modal open={openModal} onClose={closeModal}>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000, // Added z-index to ensure modal is above other content
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                width: "80%",
                maxWidth: "800px",
                position: "relative",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              {/* Close button */}
              <button
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "53px",
                  height: "53px",
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#333",
                  fontWeight: "bold",
                  fontSize: "17px",
                  zIndex: 2000, // Ensure button appears above other content
                }}
                onClick={closeModal}
              >
                &times;
              </button>

              <div
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#fff",
                  zIndex: "1",
                  paddingBottom: "10px",
                }}
              >
                <h2>Transaction history</h2>
                <select
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  style={{
                    marginBottom: "10px",
                    padding: "5px 40px 5px 10px",
                    borderRadius: "5px",
                    borderColor: "#ccc",
                    width: "740px",
                    height: "50px",
                    fontFamily: "Montserrat",
                    appearance: "none",
                    backgroundImage: `url(${transactionFilter})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 10px center",
                    backgroundSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  <option value="all">All transactions</option>
                  <option value="3months">Last 3 months</option>
                  <option value="6months">Last 6 months</option>
                  <option value="1year">Last 1 year</option>
                </select>
              </div>

              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 10px",
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                <h6 style={{ width: "25%", textAlign: "left" }}>Service</h6>
                <h6 style={{ width: "25%", textAlign: "center" }}>Date</h6>
                <h6 style={{ width: "25%", textAlign: "center" }}>
                  Transaction type
                </h6>
                <h6 style={{ width: "25%", textAlign: "right" }}>Amount</h6>
              </div>

              <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                {filteredTransactions.map((x) => (
                  <div
                    key={x.id}
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 10px",
                      alignItems: "center",
                      fontWeight: "normal",
                    }}
                  >
                    <p style={{ width: "25%", textAlign: "left" }}>
                      {x.comment}
                    </p>
                    <p style={{ width: "25%", textAlign: "center" }}>
                      {new Date(x.createdAt).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </p>
                    <p style={{ width: "25%", textAlign: "center" }}>-</p>
                    <p
                      style={{
                        width: "25%",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      <span
                        style={{
                          color: x.transactionType === 1 ? "green" : "red",
                          fontSize: "1.5em",
                        }}
                      >
                        {x.transactionType === 1 ? "+ " : "- "}
                      </span>
                      ₹{x.amount}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {claimModal && (
        <Modal open={claimModal} onClose={closeClaimModal}>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "40px",
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                position: "relative",
                maxHeight: "500px",
                overflowY: "auto",
                width: "650px",
              }}
            >
              <h2>
                {isContinueClicked
                  ? "Check carefully before saving the claim reason"
                  : "Claim reason"}
              </h2>
              {isContinueClicked && (
                <p>*Please note once saved, it cannot be changed</p>
              )}

              <button
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#333",
                }}
                onClick={closeClaimModal}
              >
                &times;
              </button>

              {isContinueClicked ? (
                ""
              ) : (
                <button
                  onClick={addNewField}
                  style={{
                    marginBottom: "20px",
                    color: "#ef7c01",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "480px",
                  }}
                >
                  + Add new
                </button>
              )}

              <div>
                {(Array.isArray(claimIcdReason) && claimIcdReason.length > 0
                  ? claimIcdReason
                  : [{}]
                ).map((reason, index) => (
                  <div key={index} style={{ marginBottom: "20px" }}>
                    <label htmlFor={`chiefComplaint${index}`}>
                      Chief complaint/diagnosis*
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id={`chiefComplaint${index}`}
                      value={reason.label || ""}
                      onChange={(e) => {
                        handleChiefComplaintChange(e, index);
                        searchIcd(e.target.value, index);
                      }}
                      placeholder="Select the ICD code"
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />

                    {reason.change && reason.suggestions.length > 0 && (
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: 0,
                          margin: 0,
                          maxHeight: "130px",
                          overflowY: "auto",
                          background: "#fff",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          position: "absolute",
                          zIndex: 1000,
                          width: "100%",
                        }}
                      >
                        {reason.suggestions.map((suggestion, i) => (
                          <li
                            key={i}
                            style={{
                              padding: "10px",
                              cursor: "pointer",
                              borderBottom: "1px solid #eee",
                            }}
                            onClick={() =>
                              handleSelectSuggestion(index, suggestion)
                            }
                          >
                            {suggestion.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <label htmlFor="claimReason">Claim reason description</label>
                <input
                  type="text"
                  id="claimReason"
                  value={claimReasons || ""}
                  onChange={handleClaimDescriptionChange}
                  placeholder="Enter here"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>

              <button
                disabled={!isContinueEnabled()} // Enable if at least one field is filled
                onClick={() => {
                  handleContinue();
                  handleClaimReason(menuFor.id);
                }}
                style={{
                  backgroundColor: isContinueEnabled() ? "#ef7c01" : "#ccc",
                  color: "#fff",
                  padding: "15px 30px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: isContinueEnabled() ? "pointer" : "not-allowed",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                {isContinueClicked ? "Save" : "Continue"}
              </button>
            </div>
          </div>
        </Modal>
      )}

      {remarksModal && (
        <Modal open={remarksModal} onClose={closeRemarksModal}>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "40px",
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                width: "80%",
                maxWidth: "800px",
                position: "relative",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              <button
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#333",
                }}
                onClick={closeRemarksModal}
              >
                &times;
              </button>
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  marginBottom: "20px",
                }}
              >
                Remarks
              </h2>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "16px",
                        paddingBottom: "20px",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "16px",
                        paddingBottom: "20px",
                      }}
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "10px 0",
                            verticalAlign: "top",
                            fontWeight: "bold",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            paddingRight: "20px",
                          }}
                        >
                          {item.status}
                        </td>
                        <td
                          style={{
                            padding: "10px 0",
                            fontSize: "14px",
                            lineHeight: "1.5",
                          }}
                        >
                          {item.remarks}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={2}
                        style={{ padding: "10px 0", textAlign: "center" }}
                      >
                        No remarks available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}

      {documentModal && (
        <Modal open={documentModal} onClose={closeDocumentModal}>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                width: "80%",
                maxWidth: "800px",
                position: "relative",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              <h2>Claim documents</h2>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h6 style={{ marginRight: "40px" }}>Document ID</h6>
                <h6 style={{ marginRight: "80px" }}>Upload date</h6>
                <h6>Document name</h6>
              </div>
              {documentData.map((x) => (
                <div
                  key={x?.id}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginRight: "90px" }}>{x.id}</p>
                  <p style={{ marginRight: "auto" }}>
                    {new Date(x.createdAt).toLocaleDateString(undefined, {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                  <p
                    style={{
                      flex: "1",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: "90px",
                      marginLeft: "90px",
                    }}
                  >
                    {x.title}
                  </p>
                  <button
                    onClick={() => viewDocument(x.url, token)}
                    style={{
                      backgroundColor: "#00c9d3",
                      color: "white",
                      padding: "9px 15px",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "5px",
                      width: "200px",
                      fontSize: "15px",
                      height: "40px",
                    }}
                  >
                    View document
                  </button>
                </div>
              ))}
              <div className="modal-content">
                <button
                  className="navigo-appoint-upload-dialog-button-1"
                  onClick={handleUploadReportClick}
                  disabled={uploading}
                  style={{
                    backgroundColor: uploading ? "#d8d8d8" : "#ef7c01",
                    color: "white",
                    border: "none",
                    outline: "none",
                    position: "relative",
                    cursor: "pointer",
                    padding: "10px",
                    fontSize: "20px",
                    marginTop: "20px",
                    width: "100%",
                    maxWidth: "800px",

                    height: "60px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {!uploading && report === false && (
                    <div className="navigo-appoint-upload-dialog-button-1-layout-1">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span>Upload a new document</span>
                    </div>
                  )}
                  {uploading && <div>Uploading...</div>}
                  {!uploading && report === true && (
                    <div className="navigo-appoint-upload-dialog-button-1-layout-1">
                      <img src={uploadIcon} alt="Upload Icon" />
                      <span>Upload a new document</span>
                    </div>
                  )}
                </button>
                <input
                  type="file"
                  hidden
                  accept={"application/pdf,image/png,image/jpeg,image/jpg"}
                  ref={uploadFileRef}
                  onChange={handleUploadFile}
                />
                <button
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    width: "53px",
                    height: "53px",
                    borderRadius: "70%",
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#333",
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                  onClick={closeDocumentModal}
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {rejectModal && (
        <Modal open={rejectModal} onClose={handleCloseModal}>
          <div className="custom-modal-content">
            {isContinueClicked ? (
              <h2 style={{ marginTop: "10px", marginBottom: "25px" }}>
                Confirm carefully before rejecting the claim
              </h2>
            ) : (
              <h2 style={{ marginTop: "10px", marginBottom: "25px" }}>
                Reject claim
              </h2>
            )}
            {isContinueClicked ? (
              <p>*Please note once rejected cannot be changed</p>
            ) : (
              ""
            )}
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Reason for rejection</InputLabel>
              <Select
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                label="Reason for rejection"
                disabled={isContinueClicked}
                error={!reason && isContinueClicked}
              >
                {reasonList.map((item, index) => (
                  <MenuItem key={index} value={item.reason}>
                    {item.reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Remarks (to be shared with user)"
              variant="outlined"
              fullWidth
              value={remarks}
              required
              onChange={(e) => setRemarks(e.target.value)}
              disabled={isContinueClicked}
              style={{ marginTop: "15px" }}
            />

            <Button
              fullWidth
              variant="contained"
              onClick={(e) => {
                if (isContinueClicked) {
                  rejectClaim(id, walletId, reason, remarks);
                } else {
                  handleContinue(claimId);
                }
              }}
              className="full-width-button"
              disabled={!isFormFilled}
              style={{
                backgroundColor: isFormFilled ? "#f57c00" : "#e0e0e0",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              {isContinueClicked ? "Reject the claim" : "Continue"}
            </Button>
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "53px",
                height: "53px",
                borderRadius: "70%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#333",
                fontWeight: "bold",
                fontSize: "17px",
              }}
              onClick={handleCloseModal}
            >
              &times;
            </button>
          </div>
        </Modal>
      )}
      {processedModal && (
        <Modal open={processedModal} onClose={handleCloseModal}>
          <div className="custom-modal-content">
            {isContinueClicked ? (
              <h2 style={{ marginTop: "10px", marginBottom: "25px" }}>
                Check carefully before processing the claim
              </h2>
            ) : (
              <h2 style={{ marginTop: "10px", marginBottom: "25px" }}>
                Process claim
              </h2>
            )}
            {isContinueClicked ? (
              <p>
                *Please note once processed, the transaction ID cannot be
                changed.
              </p>
            ) : (
              ""
            )}
            <TextField
              required
              label="Transaction ID"
              variant="outlined"
              fullWidth
              value={processClaim}
              onChange={(e) => setProcessClaim(e.target.value)}
              disabled={isContinueClicked}
              style={{ marginTop: "15px" }}
            />

            <TextField
              label="Remarks (to be shared with user)"
              variant="outlined"
              fullWidth
              value={processRemarks}
              onChange={(e) => setProcessRemarks(e.target.value)}
              disabled={isContinueClicked}
              style={{ marginTop: "15px" }}
            />

            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (isContinueClicked) {
                  processedClaim(id, walletId, processClaim, processRemarks);
                } else {
                  handleContinue(claimId);
                }
              }}
              className="full-width-button"
              disabled={!isFormFilled}
              style={{
                backgroundColor: isFormFilled ? "#f57c00" : "#e0e0e0",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              {isContinueClicked ? "Save" : "Continue"}
            </Button>
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "53px",
                height: "53px",
                borderRadius: "70%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#333",
                fontWeight: "bold",
                fontSize: "17px",
              }}
              onClick={handleCloseModal}
            >
              &times;
            </button>
          </div>
        </Modal>
      )}
      {onHoldModal && (
        <Modal open={onHoldModal} onClose={handleCloseModal}>
          <div className="custom-modal-content scrollable-modal-full-height">
            <h2 style={{ marginTop: "10px", marginBottom: "25px" }}>
              Hold claim
            </h2>

            {(clickedClaimTicketEvents ?? []).map((e) => ((e?.id ?? 0) == 6) ? <TextField
              label={`Previous on hold remarks (shared with user on ${(moment.utc(e?.createdAt).local().format("DD/MM/YYYY [at] hh:mmA"))})`}
              variant="outlined"
              fullWidth
              required
              disabled
              value={e?.comment ?? ""}
              style={{ marginTop: "15px"}}
            /> : <div/>)}

            <TextField
              label="Remarks (to be shared with user)"
              variant="outlined"
              fullWidth
              required
              value={onHoldRemarks}
              onChange={(e) => setOnHoldRemarks(e.target.value)}
              style={{ marginTop: "15px" }}
            />

            <div style={{ fontSize: "12px", fontWeight: 400, display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onHoldUploadDocumentsAgainCheckbox ?? false}
                    color="secondary"
                    onChange={(e) =>
                      setOnHoldUploadDocumentsAgainCheckbox(e.target.checked)}
                  />
                }
                label="Activate upload documents button for this claim."
              />
            </div>

            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onHoldClaim(id, walletId, onHoldRemarks, onHoldUploadDocumentsAgainCheckbox);
              }}
              className="full-width-button"
              disabled={!isFormFilled || isClicked}
              style={{
                backgroundColor: (isFormFilled && !isClicked) ? "#f57c00" : "#e0e0e0",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              Save
            </Button>
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "53px",
                height: "53px",
                borderRadius: "70%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#333",
                fontWeight: "bold",
                fontSize: "17px",
              }}
              onClick={handleCloseModal}
            >
              &times;
            </button>
          </div>
        </Modal>
      )}
      {approvedModal && (
        <Modal open={approvedModal} onClose={handleCloseModal}>
          <div className="custom-modal-content">
            {isContinueClicked ? (
              <h2 style={{ marginTop: "10px", marginBottom: "25px" }}>
                Check carefully before approving the claim
              </h2>
            ) : (
              <h2 style={{ marginTop: "10px", marginBottom: "25px" }}>
                Approve claim
              </h2>
            )}
            {isContinueClicked ? (
              <p>*Please note once amount is deducted it cannot be undone.</p>
            ) : (
              ""
            )}
            <TextField
              required
              label="Approved claim amount"
              variant="outlined"
              fullWidth
              value={approveClaim}
              onChange={(e) => setApproveClaim(e.target.value)}
              disabled={isContinueClicked}
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
              }}
              style={{ marginTop: "15px" }}
            />

            <TextField
              label="Remarks (to be shared with user)"
              variant="outlined"
              fullWidth
              value={approveRemarks}
              onChange={(e) => setApproveRemarks(e.target.value)}
              disabled={isContinueClicked}
              style={{ marginTop: "15px" }}
            />

            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                if (isContinueClicked) {
                  deductMoney(id, walletId, approveClaim, approveRemarks);
                } else {
                  handleContinue(claimId);
                }
              }}
              className="full-width-button"
              disabled={!isFormFilled || isClicked}
              style={{
                backgroundColor: (isFormFilled && !isClicked) ? "#f57c00" : "#e0e0e0",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              {isContinueClicked ? "Deduct amount from wallet" : "Continue"}
            </Button>
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "53px",
                height: "53px",
                borderRadius: "70%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#333",
                fontWeight: "bold",
                fontSize: "17px",
              }}
              onClick={handleCloseModal}
            >
              &times;
            </button>
          </div>
        </Modal>
      )}
      {icdModal && (
        <Modal open={icdModal} onClose={closeIcdModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "650px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button
              onClick={() => {
                resetFormFields();
                setClaimModal(true);
                setIcdModal(false);
              }}
              style={{
                backgroundColor: "#00bcd4",
                color: "#fff",
                padding: "8px 20px",
                fontWeight: "600",
                fontFamily: "Montserrat",
                borderRadius: "5px",
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                width: "120px",
                float: "right",
                marginRight: "50px",
              }}
            >
              + Add New
            </Button>
            <h2
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginBottom: "20px",
              }}
            >
              Claim reason
            </h2>
            {claimIcdReason.length > 0 ? (
              claimIcdReason.map((reason, index) => (
                <Box key={index} sx={{ marginBottom: "20px" }}>
                  <TextField
                    fullWidth
                    label="Chief complaint/diagnosis"
                    value={reason.label}
                    variant="outlined"
                    sx={{
                      fontFamily: "Montserrat",
                    }}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "#f9f9f9",
                        fontWeight: "600",
                      },
                    }}
                  />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ccc",
                  padding: "40px",
                }}
              >
                <img
                  src={calender}
                  alt="No Claim Icon"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "20px",
                    marginLeft: "90px",
                  }}
                />
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    marginLeft: "90px",
                    color: "lightgray",
                  }}
                >
                  No reason added
                </p>
              </Box>
            )}
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "53px",
                height: "53px",
                borderRadius: "70%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#333",
                fontWeight: "bold",
                fontSize: "17px",
              }}
              onClick={closeIcdModal}
            >
              &times;
            </button>
            <Box sx={{ marginTop: "30px" }}>
              {claimIcdReason.length > 0 && (
                <TextField
                  fullWidth
                  label="Claim reason description"
                  value={claimReasons}
                  variant="outlined"
                  sx={{
                    fontFamily: "Montserrat",
                  }}
                  InputProps={{
                    readOnly: true,
                    style: {
                      backgroundColor: "#f9f9f9",
                      fontWeight: "400",
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Modal>
      )}

      {openClaimFileDialog && (
        <Modal open={openClaimFileDialog} onClose={handleCloseModal}>
          <div className="custom-modal-content scrollable-modal-full-height">
            <div style={{ marginTop: "10px", marginBottom: "15px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <h2 style={{ fontSize: "24px", fontWeight: 800 }}>
                File new claim
              </h2>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                  onClick={() =>
                    helper.navigateTo(
                      props,
                      "/miscellaneous/claims-dashboard/faqs"
                    )
                  }
                >
                  <div style={{ fontWeight: 900, fontSize: "16px", color: "#F97F0F" }}>
                    FAQs
                  </div>
                  <img src={helpIcon}></img>
                </div> */}
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    width: "36px",
                    height: "36px",
                    borderRadius: "70%",
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#333",
                    fontWeight: "bold",
                    fontSize: "24px",
                  }}
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
              </div>
            </div>



            <TextField
              label="Patient ID"
              variant="outlined"
              fullWidth
              required
              value={claimFileFormFields.pid ?? ""}
              onChange={(e) => updateClaimFileFormFields({ pid: e.target.value })}
              disabled={(editClaimTypeClaimTypesArr?.length > 0)}
              style={{ marginTop: "15px" }}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={(e) => {
                      fetchPatientDetailsById(claimFileFormFields.pid);
                      getAllClaimTypes(claimFileFormFields.pid);
                      getAllFamilyMembers(claimFileFormFields.pid);
                    }}>
                      <img src={arrowCircleRightIcon} alt="Search" width="24" height="24" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Patient name"
              variant="outlined"
              fullWidth
              value={claimFileFormFields.patientName ?? ""}
              disabled
              style={{ marginTop: "15px" }}
            />

            <TextField
              label="Account code"
              variant="outlined"
              fullWidth
              value={claimFileFormFields.clientCode ?? ""}
              disabled
              style={{ marginTop: "15px" }}
            />

            {(editClaimTypeClaimTypesArr?.length > 0) && <FormControl style={{ marginTop: "15px" }} fullWidth variant="outlined" required>
              <InputLabel>Applying claim for</InputLabel>
              <Select
                value={claimFileFormFields?.claimedFor}
                onChange={(e) => {
                  updateClaimFileFormFields({ claimedFor: e.target.value });
                }}
                disabled={isFileClaimContinueClicked}
                label="Applying claim for"
              >
                {[...fileClaimFamilyMembersArr, { memberPatientId: 0, firstName: "Self", relationString: "" }].map((item, index) => (
                  <MenuItem key={index} value={item.memberPatientId}>
                    {`${item.firstName} ${item.relationString}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}

            {(editClaimTypeClaimTypesArr?.length > 0) && <FormControl style={{ marginTop: "15px" }} fullWidth variant="outlined" required>
              <InputLabel>Claim type</InputLabel>
              <Select
                value={claimFileFormFields?.reimbursementTypeId}
                onChange={(e) => {
                  updateClaimFileFormFields({ reimbursementTypeId: e.target.value });
                  getClaimTypeLimit(claimFileFormFields.pid, e.target.value);
                  editClaimTypeClaimTypesArr.forEach((ele) => {
                    if (ele.typeId == e.target.value) {
                      setclaimTypeInstructions(ele.instructions);
                      updateClaimFileFormFields({ reimbursementTypeName: ele.typeName });
                    }
                  });
                }}
                disabled={(!(editClaimTypeClaimTypesArr?.length > 0)) || isFileClaimContinueClicked}
                label="Claim type"
              >
                {editClaimTypeClaimTypesArr.map((item, index) => (
                  <MenuItem key={index} value={item.typeId}>
                    {item.typeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}

            {(isFileClaimContinueClicked && editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.limit) && editClaimTypeLimitRes?.limit != 0) && <TextField
              label="Available limit on selected claim type"
              variant="outlined"
              fullWidth
              value={`Rs ${editClaimTypeLimitRes?.available ?? "NA"} out of Rs ${editClaimTypeLimitRes?.limit ?? "NA"}`}
              disabled
              style={{ marginTop: "15px" }}
            />}

            {(isFileClaimContinueClicked && Boolean(claimFileFormFields?.reimbursementTypeId)) && <TextField
              label="Claim amount (Rs)"
              variant="outlined"
              fullWidth
              required
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
              }}
              value={claimFileFormFields?.claimAmount}
              onChange={(e) => { updateClaimFileFormFields({ claimAmount: e.target.value }); }}
              disabled={!Boolean(claimFileFormFields?.reimbursementTypeId)}
              style={{ marginTop: "15px" }}
            />}

            {isFileClaimContinueClicked && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              {(editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.limit) && editClaimTypeLimitRes?.limit != 0 && (claimFileFormFields?.claimAmount > editClaimTypeLimitRes?.available))
                && <div style={{ fontSize: "12px", fontWeight: 400, display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    disabled={recheckEditClaimTypeCarefully}
                    control={
                      <Checkbox
                        checked={claimFileFormFields?.ignoreReimbursementLimit ?? false}
                        color="secondary"
                        onChange={(e) =>
                          updateClaimFileFormFields({ ignoreReimbursementLimit: e.target.checked })}
                      />
                    }
                    label="Sublimit exception applied"
                  />
                </div>}
              {(editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.limit) && editClaimTypeLimitRes?.limit != 0 && (claimFileFormFields?.claimAmount > editClaimTypeLimitRes?.available))
                && <div style={{ fontSize: "10px", fontWeight: 500, color: "#E44437" }}>
                  * Claim amount exceeded the available sublimit under selected claim type.
                </div>}
            </div>}




            {(isFileClaimContinueClicked && Boolean(claimFileFormFields?.reimbursementTypeId)) &&
              <TextField
                className="custom-date-picker-default-icon-hidden"
                label="Date of Invoice"
                variant="outlined"
                type="date"
                fullWidth
                required
                inputRef={dateOfInvoiceInputRef}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: { cursor: "pointer" },
                  min: claimFileFormFields?.minInvoiceDate ?? getMinInvoiceDate(3650),
                  max: claimFileFormFields?.maxInvoiceDate ?? getMaxInvoiceDate(),
                }}
                value={claimFileFormFields?.invoiceDate}
                style={{ marginTop: "15px", cursor: 'pointer' }}
                onChange={(e) => { updateClaimFileFormFields({ invoiceDate: e.target.value }) }}
                onClick={openDatePicker}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                      <FontIcon>date_range</FontIcon>
                    </InputAdornment>
                  ),
                }}
              />}


            {isFileClaimContinueClicked && <>

              <h4 style={{ marginTop: "25px", marginBottom: "15px" }}>
                Upload documents
              </h4>


              {(claimTypeInstructions?.length > 0 && (documentDataWithStatus?.length <= 0)) &&
                <div style={{ fontSize: 12, border: "1px solid #CED4DA", borderRadius: "4px", paddingTop: "10px", paddingLeft: "0px", paddingRight: "10px", paddingBottom: "10px" }}>
                  {claimTypeInstructions.map((item, index) =>
                    (<div key={index} dangerouslySetInnerHTML={{ __html: item?.instruction }}></div>)
                  )}
                </div>
              }

              {(documentDataWithStatus?.length <= 0) && <div style={{ marginTop: "25px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div style={{ fontSize: "12px", fontWeight: 400 }}>You can upload upto 5 files here</div>
                <div style={{ fontSize: "12px", fontWeight: 500 }}>
                  (Max size 20 MB)
                </div>
              </div>}




              {documentDataWithStatus.map((doc, index) => (
                <div key={index} className={doc.status == "error" ? "document-box-div-error" : "document-box-div"} onClick={() => openDocumentInNewTab(doc.url)}>

                  <div style={{ fontSize: 14, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}> {doc.name}</div>
                  {doc.status == "uploading" && <CircularProgress style={{ margin: 0 }} />}
                  {(doc.status == "selected" || doc.status == "error") && <button
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      width: "24px",
                      height: "24px",
                      borderRadius: "70%",
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#333",
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                    onClick={(event) => removeDocument(event, index)}
                  >
                    &times;
                  </button>}

                  {doc.status == "uploaded" && <img src={checkCircle}></img>}


                </div>
              ))}


              <input
                type="file"
                hidden
                multiple
                accept={"application/pdf,image/png,image/jpeg,image/jpg"}
                ref={uploadDocumentRef}
                onChange={handleDocFileInput}
              />

              {(documentDataWithStatus?.length > 0) &&
                <div style={{ marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button
                    style={{ color: "#f57c00", fontSize: "16px", borderRadius: "5px", fontWeight: 700, padding: "5px" }}
                    onClick={(e) => { handleUploadDocumentClick(); }}
                  >Add more documents
                  </Button>
                </div>
              }
              {(Boolean(documentDataWithStatus?.length > 0) && editClaimTypeClaimConfig && editClaimTypeClaimConfig?.documentType && editClaimTypeClaimConfig?.documentType?.heading && editClaimTypeClaimConfig?.documentType?.heading != "")
                && <>
                  <h4 style={{ marginTop: "25px", marginBottom: "15px" }}>
                    {editClaimTypeClaimConfig?.documentType?.heading}
                  </h4>



                  <div style={{ fontSize: 12, border: "1px solid #CED4DA", borderRadius: "4px", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                    {editClaimTypeClaimConfig?.documentType?.options.map((item, index) =>

                    (<div key={item?.key} style={{ fontSize: "12px", fontWeight: 400, display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start" }}>
                      <FormControlLabel
                        style={{ fontSize: "12px", fontWeight: 400, display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start" }}
                        disabled={recheckEditClaimTypeCarefully}
                        control={
                          <Checkbox
                            style={{ fontSize: "12px", fontWeight: 400, display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start" }}
                            checked={(claimFileFormFields?.documentType == item?.key)}
                            color="secondary"
                            onChange={(e) => {
                              if (e.target.checked) {
                                updateClaimFileFormFields({ documentType: item?.key })
                              }
                            }}
                          />
                        }
                        label={<div style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: item?.value }}></div>}
                      />
                    </div>)
                    )}
                  </div>
                </>
              }

            </>}


            {!isFileClaimContinueClicked && <Button
              fullWidth
              variant="contained"
              onClick={(e) => {
                setIsFileClaimContinueClicked(true);
              }}
              className="full-width-button"
              disabled={!Boolean(claimFileFormFields?.pid && (claimFileFormFields?.claimedFor || claimFileFormFields?.claimedFor == 0) && claimFileFormFields?.reimbursementTypeId && claimFileFormFields?.patientName && claimFileFormFields?.clientCode)}
              style={{
                backgroundColor: Boolean(claimFileFormFields?.pid && (claimFileFormFields?.claimedFor || claimFileFormFields?.claimedFor == 0) && claimFileFormFields?.reimbursementTypeId && claimFileFormFields?.patientName && claimFileFormFields?.clientCode) ? "#f57c00" : "#e0e0e0",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              Continue
            </Button>}

            {isFileClaimContinueClicked && <Button
              fullWidth
              variant="contained"
              onClick={(e) => {
                if (documentDataWithStatus.length > 0) {

                  let allDocsUploaded = true;
                  let docsIds = [];
                  documentDataWithStatus.forEach((e) => {
                    if (e.status != 'uploaded') {
                      allDocsUploaded = false;
                    }
                    docsIds = [...docsIds, e.uploadedDocId]
                  })

                  if (allDocsUploaded) {
                    // if all docs already uploaded then directly create reimbursement
                    createReimbursement();
                  } else {
                    handleApplyForClaim();
                  }
                }
                else {
                  handleUploadDocumentClick();
                }
              }}
              className="full-width-button"
              disabled={!isValidClaimFile || isClicked}
              style={{
                backgroundColor: (isValidClaimFile && !isClicked) ? "#f57c00" : "#e0e0e0",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              {documentDataWithStatus.length > 0 ? "Apply for claim" : "Upload documents"}
            </Button>}


          </div>
        </Modal>
      )}


      {openEditClaimTypeDialog && (
        <Modal open={openEditClaimTypeDialog} onClose={handleCloseModal}>
          <div className="custom-modal-content scrollable-modal-full-height">
            <div style={{ marginTop: "10px", marginBottom: "15px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <h2 style={{ fontSize: "24px", fontWeight: 800 }}>
                {recheckEditClaimTypeCarefully ? "Check carefully before updating the claim type" : "Edit claim type"}
              </h2>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer" }} 
                onClick={() =>
                  helper.navigateTo(
                    props,
                    "/miscellaneous/claims-dashboard/faqs"
                  )
                }
                >
                  <div style={{ fontWeight: 900, fontSize: "16px", color: "#F97F0F" }}>
                    FAQs
                  </div>
                  <img src={helpIcon}></img>
                </div> */}
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    width: "36px",
                    height: "36px",
                    borderRadius: "70%",
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#333",
                    fontWeight: "bold",
                    fontSize: "24px",
                  }}
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
              </div>
            </div>



            <TextField
              label="Claim ID"
              variant="outlined"
              fullWidth
              required
              value={editClaimFormFields.claimId ?? "Not Found"}
              disabled={true}
              style={{ marginTop: "15px" }}
            />

            <TextField
              label="Patient name"
              variant="outlined"
              fullWidth
              value={editClaimFormFields.patientFullName ?? "Not Found"}
              disabled={true}
              style={{ marginTop: "15px" }}
            />

            <TextField
              label="Applying claim for"
              variant="outlined"
              fullWidth
              required
              value={(editClaimFormFields?.claimedFor == null || editClaimFormFields?.claimedFor == undefined) ? "Not Found" : ((editClaimFormFields?.claimedFor ?? 0) == 0) ? "Self" : `${editClaimFormFields.claimedForName} (${editClaimFormFields.claimedForRelationName ?? ""})`}
              disabled={true}
              style={{ marginTop: "15px" }}
            />

            <TextField
              label="Account code"
              variant="outlined"
              fullWidth
              value={editClaimFormFields.clientCode ?? "Not Found"}
              disabled={true}
              style={{ marginTop: "15px" }}
            />

            <FormControl style={{ marginTop: "15px" }} fullWidth variant="outlined" required>
              <InputLabel>Claim type</InputLabel>
              <Select
                value={editClaimTypeField}
                onChange={(e) => {
                  setEditClaimTypeField(e.target.value);
                  getClaimTypeLimit(editClaimFormFields.patientId, e.target.value);
                }}
                disabled={recheckEditClaimTypeCarefully}
                label="Claim type"
                error={!editClaimTypeField}
              >
                {editClaimTypeClaimTypesArr.map((item, index) => (
                  <MenuItem key={index} value={item.typeId}>
                    {item.typeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            {(editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.limit) && editClaimTypeLimitRes?.limit != 0) && <TextField
              label="Available limit on selected claim type"
              variant="outlined"
              fullWidth
              value={`Rs ${editClaimTypeLimitRes?.available ?? "NA"} out of Rs ${editClaimTypeLimitRes?.limit ?? "NA"}`}
              disabled
              style={{ marginTop: "15px" }}
            />}

            <TextField
              label="Claim amount (Rs)"
              variant="outlined"
              fullWidth
              required
              value={editClaimFormFields.claimAmount ?? "Not Found"}
              disabled={true}
              style={{ marginTop: "15px" }}
            />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              {(editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.typeId != editClaimFormFields?.reimbursementTypeId) && Boolean(editClaimTypeLimitRes?.limit) && editClaimTypeLimitRes?.limit != 0 && (editClaimFormFields?.claimAmount > editClaimTypeLimitRes?.available))
                && <div style={{ fontSize: "12px", fontWeight: 400, display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    disabled={recheckEditClaimTypeCarefully}
                    control={
                      <Checkbox
                        checked={claimLimitExceptionCheckbox}
                        color="secondary"
                        onChange={(e) =>
                          setClaimLimitExceptionCheckbox(e.target.checked)}
                      />
                    }
                    label="Sublimit exception applied"
                  />
                </div>}
              {(editClaimTypeLimitRes && Boolean(editClaimTypeLimitRes?.typeId != editClaimFormFields?.reimbursementTypeId) && Boolean(editClaimTypeLimitRes?.limit) && editClaimTypeLimitRes?.limit != 0 && (editClaimFormFields?.claimAmount > editClaimTypeLimitRes?.available))
                && <div style={{ fontSize: "10px", fontWeight: 500, color: "#E44437" }}>
                  * Claim amount exceeded the available sublimit under selected claim type.
                </div>}
            </div>

            <TextField
              label="Date of Invoice"
              variant="outlined"
              fullWidth
              required
              value={editClaimFormFields.invoiceDate == null ? "Not Found" : parseDateFromISO(editClaimFormFields.invoiceDate)}
              disabled
              style={{ marginTop: "15px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontIcon>date_range</FontIcon>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Remarks (for internal purposes)"
              variant="outlined"
              fullWidth
              required
              value={editClaimTypeReason}
              onChange={(e) => setEditClaimTypeReason(e.target.value)}
              disabled={recheckEditClaimTypeCarefully}
              style={{ marginTop: "45px" }}
            />

            {/* <div className="appointment-dialog-main-section-5-left">
              <p>Date</p>
              <div className="appointment-dialog-main-section-5-left-date">
                <FontIcon>date_range</FontIcon>
                <DatePicker
                  value={appointDate}
                  onChange={(e) => {
                    setAppointDate(e);
                    setFetchingSlot(true);
                    getTimeSlots(e);
                  }}
                  className="appointment-dialog-main-section-5-left-datepicker"
                  format="DD/MM/YYYY"
                  placeholder="Choose a date"
                  disabled={
                    false
                    // type === "" ||
                    // isEdit ||
                    // (currentService.isLabTest && !addressSelected.id)
                  }
                  minDate={new Date()}
                  maxDate={new Date()}
                />
              </div>
            </div> */}


            <Button
              fullWidth
              variant="contained"
              onClick={(e) => {
                console.log("clicked");
                if (!recheckEditClaimTypeCarefully) {
                  setRecheckEditClaimTypeCarefully(true);

                } else {
                  updateClaimType(editClaimFormFields?.id, editClaimFormFields?.walletId, editClaimTypeField, claimLimitExceptionCheckbox, editClaimTypeReason);
                }
              }}
              className="full-width-button"
              disabled={!isValidClaimTypeChange || isClicked}
              style={{
                backgroundColor: (isValidClaimTypeChange && !isClicked) ? "#f57c00" : "#e0e0e0",
                color: "#fff",
                marginTop: "20px",
              }}
            >
              {recheckEditClaimTypeCarefully ? "Update" : "Edit claim type"}
            </Button>


          </div>
        </Modal>
      )}

    </div>
  );
}
export default ClaimsDashboard;
